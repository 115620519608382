import React from "react"
import Slider from 'react-slick'
import "../assets/vendor/slick-1.5.9/slick/slick.css"; 
import "../assets/vendor/slick-1.5.9/slick/slick-theme.scss";

const ArticleCarousel = ({ articles }) => {


    console.log("ARTICLES ", articles);
    const settings = {
        infinite: false,
        slidesToShow: 4.5,
        slidesToScroll: 4,
        responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3.5,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1.5,
            slidesToScroll: 1
          }
        }]
      };

    let tiles = articles.map((edge, i) => {
         var article = edge.node.data;
            return (
                <div key={ i }>
                    <a href={`/article/${edge.node.uid}/`}>
                        <figure className="style-1">
                            {
                                article.spot_image &&
                                <img src={article.spot_image.url} alt={article.spot_image.alt} className="h-fill" />
                            }
                            <figcaption>
                                <h3 className="title">{article.article_title.text}</h3>
                                {
                                    article.article_summary &&
                                    <p className="description">{article.article_summary}</p>
                                }
                            </figcaption>
                        </figure>
                    </a> 
                </div>
            );
        })
    

    console.log("Tiles ", tiles);
    return (
        <Slider {...settings} id="slick-carousel" 
            nextArrow={
                <a href="#" className="slick-fix-right"><a href="#"><img className="m-b-0 slider-arrow" alt="Right-facing red chevron" src="/images/chevron-right.svg"/></a></a>
            }
            prevArrow={
                <a href="#" className="slick-fix-left"><a href="#"><img className="m-b-0 slider-arrow" alt="Left-facing red chevron" src="/images/chevron-left.svg"/></a></a>
            }
            className="slick-carousel-home"> 
            { tiles }
        </Slider>
    )
}


export default ArticleCarousel;